<template>
    <section id="features" :aria-label="t('title')" class="relative w-full">
        <BaseContainer
            class="flex flex-col items-center justify-center py-20 backdrop-blur-sm md:py-28"
        >
            <h2 class="mb-4 text-center text-4xl font-semibold">
                {{ t('title') }}
            </h2>

            <p class="text-center text-lg leading-7">{{ t('subtitle') }}</p>

            <div class="mt-10 flex w-full flex-col gap-10 md:mt-20">
                <BaseFeatureCard
                    v-for="(feature, i) in features"
                    :key="feature.name"
                    :reversed="i % 2 !== 0"
                    :title="t(feature.name)"
                    :icon-src="`/icons/${feature.name}.svg`"
                    :items="feature.bulletPoints"
                    :images="feature.screenshots"
                    :svgs="feature.svgs"
                />
            </div>
        </BaseContainer>
    </section>
</template>

<script setup lang="ts">
import type { FeatureCardSVG } from '../base/FeatureCard.vue'

export type FeatureName =
    | 'revenues'
    | 'expenses'
    | 'charts'
    | 'contacts'
    | 'items'
    | 'settings'

interface Feature {
    name: FeatureName
    bulletPoints: string[]
    screenshots: string[]
    svgs: FeatureCardSVG[]
}

const { t, locale, messages } = useI18n()

const features = computed<Feature[]>(() => [
    {
        name: 'revenues',
        bulletPoints: getFeatureBullets('revenues'),
        screenshots: resolveScreenshots('revenues', [
            'revenues-1.png',
            'revenues-2.png',
            'revenues-3.png',
        ]),
        svgs: [
            {
                src: '/drawings/revenues/revenues-1.svg',
                top: -40,
                right: -36,
            },
            {
                src: '/drawings/revenues/revenues-2.svg',
                top: 30,
                right: -53,
            },
        ],
    },
    {
        name: 'expenses',
        bulletPoints: getFeatureBullets('expenses'),
        screenshots: resolveScreenshots('expenses', [
            'expenses-1.png',
            'expenses-2.png',
            'expenses-3.png',
        ]),
        svgs: [
            {
                src: '/drawings/expenses/expenses-1.svg',
                left: -110,
                top: 60,
            },
            {
                src: '/drawings/expenses/expenses-2.svg',
                bottom: 55,
                left: 27,
            },
        ],
    },
    {
        name: 'charts',
        bulletPoints: getFeatureBullets('charts'),
        screenshots: resolveScreenshots('charts', ['charts-1.png']),
        svgs: [
            {
                src: '/drawings/charts/charts-1.svg',
                top: 30,
                right: -50,
            },
            {
                src: '/drawings/charts/charts-2.svg',
                top: 70,
                right: -10,
            },
        ],
    },
    {
        name: 'contacts',
        bulletPoints: getFeatureBullets('contacts'),
        screenshots: resolveScreenshots('contacts', [
            'contacts-1.png',
            'contacts-2.png',
            'contacts-3.png',
        ]),
        svgs: [
            {
                src: '/drawings/contacts/contacts-1.svg',
                top: 10,
                left: -90,
            },
            {
                src: '/drawings/contacts/contacts-2.svg',
                top: -10,
                left: -120,
            },
            {
                src: '/drawings/contacts/contacts-3.svg',
                bottom: 40,
                right: -35,
            },
        ],
    },
    {
        name: 'items',
        bulletPoints: getFeatureBullets('items'),
        screenshots: resolveScreenshots('items', ['items-1.png']),
        svgs: [
            {
                src: '/drawings/items/items-1.svg',
                top: 50,
                right: 300,
            },
            {
                src: '/drawings/items/items-2.svg',
                top: 58,
                right: 242,
            },
            {
                src: '/drawings/items/items-3.svg',
                top: 50,
                right: 245,
            },
        ],
    },
    {
        name: 'settings',
        bulletPoints: getFeatureBullets('settings'),
        screenshots: resolveScreenshots('settings', [
            'settings-1.png',
            'settings-2.png',
            'settings-3.png',
            'settings-4.png',
            'settings-5.png',
        ]),
        svgs: [
            {
                src: '/drawings/settings/settings-1.svg',
                top: 10,
                right: 250,
            },
            {
                src: '/drawings/settings/settings-2.svg',
                top: 247,
                left: -175,
            },
        ],
    },
])

function resolveScreenshots(
    resourceName: FeatureName,
    names: string[],
): string[] {
    return names.map(
        (name) => `/screenshots/${locale.value}/${resourceName}/${name}`,
    )
}

function getFeatureBullets(feature: string) {
    // @ts-ignore silence i18n
    const featureCount = messages.value[locale.value].features[feature]
        .length as number

    return Array.from({ length: featureCount }, (_, i) =>
        t(`features.${feature}[${i}]`),
    )
}
</script>

<i18n lang="yaml">
en:
    title: 'Everything you need for your small and medium business'
    subtitle: 'Keep track of the status of your business anytime you want with the main functionalities we offer'
    revenues: 'Revenues'
    expenses: 'Expenses'
    charts: 'Charts'
    contacts: 'Contacts'
    items: 'Items'
    settings: 'Settings'
    features:
        revenues:
            - Quickly issue revenue documents
            - Create and save different types of revenue documents in your database with ease
            - Translate to Bulgarian and English, as well as choose from a variety of foreign currencies
            - Export invoices as PDF files when needed or send them directly via email
        expenses:
            - Enter all company expenses to keep track of your business's financial health
            # - You can enter them manually or through our OCR system
            - You can categorize and analyze them by type and period
            - You can also track and change their status and export them to an accounting program
        charts:
            - Detailed financial insights
            - Monitor your current financial status through various charts, including revenues, expenses, and assets with the ability to view them by categories and different periods of time
            - Transparency of your financial result and the ability to analyze the factors that led to it
        contacts:
            - Manage customers and suppliers
            - Create a detailed list of contacts from which you can choose when issuing a revenue document or entering an expense
        items:
            - Convenient organization of offered goods and services
            - Create items that you can easily and quickly use when issuing revenue documents with specific names in English and Bulgarian, unit of measure, and price
        settings:
            - Personalized settings
            - Customize the application according to your needs and preferences
            - Define individual categories for revenues and expenses for better data analysis, as well as higher-quality export to accounting programs
            - Ability to enter user roles according to desired access and many other settings

bg:
    title: 'Всичко най-необходимо за малкия и среден бизнес'
    subtitle: 'Следете състоянието на вашия бизнес по всяко време с основните функции, които предлагаме.'
    revenues: 'Приходи'
    expenses: 'Разходи'
    charts: 'Графики'
    contacts: 'Контакти'
    items: 'Артикули'
    settings: 'Настройки'
    features:
        revenues:
            - Бързо издаване на приходни документи
            - Създаване и запазване на различни видове приходни документи във Вашата база данни с лекота
            - Възможност за превод на български и английски език, както и за избор сред множество чужди валути
            - Експортиране на фактури като PDF файлове, когато е необходимо или директно изпращане по имейл
        expenses:
            - Въвеждайте всички фирмени разходи, за да следите финансовото състояние на бизнеса си
            # - Имате възможност да ги въвеждате ръчно или чрез нашата OCR система
            - Можете да ги категоризирате и анализирате според вид и период
            - Можете също така да следите и променяте статуса им, както и да ги експортирате към счетоводна програма
        charts:
            - Детайлни финансови прегледи
            - Наблюдавайте Вашето текущо финансово състояние чрез различни графики, включващи приходи, разходи и активи с възможност за преглед според категории и различен период от време
            - Прозрачност на финансовия Ви резултат и възможност да анализиратe факторите, довели до него
        contacts:
            - Управление на клиенти и доставчици
            - Съставете подробен списък с контакти, от който можете да избирате при издаване на приходен документ или въвеждане на разходен
        items:
            - Удобно организиране на предлаганите стоки и услуги
            - Създайте артикули, които лесно и бързо можете да използвате при издаване на приходни документи със специфични наименование на английски и български език, мерна единица и цена
        settings:
            - Персонализирани настройки
            - Настройте приложението според вашите нужди и предпочитания
            - Определете индивидуални категории за приходи и разходи за по-добър анализ на данните, както и по-качествен експорт към счетоводни програми
            - Възможност да въведете потребителски роли според желания достъп и още много други настройки
</i18n>
